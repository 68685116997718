import {
  GL_COLORS,
  GLBox,
  GLDetailedModal,
  useI18n,
} from "@group-link-one/grouplink-components";

import { useTrackEasyStore } from "../../TrackEasyMap/store/track-easy-map-store";
import { TrackEasyMap } from "../../TrackEasyMap/TrackEasyMap";

export function EventListModalMap() {
  const { t } = useI18n();
  const { state: trackEasyState } = useTrackEasyStore();

  return (
    <GLDetailedModal
      open={trackEasyState.eventListModalIsOpen}
      title={t("eventList.modalMap.title")}
      width="100%"
      heigth="100%"
      closeIconColor={GL_COLORS.DANGER}
      asFullScreen={true}
      content={
        <GLBox width="100%" height="100%">
          <TrackEasyMap />
        </GLBox>
      }
    />
  );
}
